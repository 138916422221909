.flex-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-row-start > * {
  margin-left: 16px !important;
  margin-top: 16px;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.flex-column-center > * {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.flex-wrap {
  flex-wrap: wrap;
}

.align-item-center {
  align-items: center;
}

.align-content-stretch {
  align-content: stretch;
}
.flex-row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.flex-column-start {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-column-start > * {
  margin-top: 16px;
}
.container {
  margin: 5px;
}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-4 {
  flex-grow: 4;
}

.flex-grow-5 {
  flex-grow: 5;
}


body {
  line-height: 200%;
  background: #eceded !important;
}

a:hover,
a *:hover {
  color: #f2b250 !important;
}

* {
  box-shadow: none !important;
}
