.navigation-button {
  margin-right: 16px !important;
}

.navigation-button:hover {
}

.navigation-button * {
  color: white !important;
}
