.avatar {
  width: 80px !important;
  height: 80px !important;
}

.staff-container {
  padding: 16px;
  width: 21rem;
  height: 17.5rem;
  justify-content: center;
  
}

.staff-container > * {
  margin-bottom: 8px !important;
  margin-bottom: 8px !important;
}

.contact-info {
  padding: 16px;
}
