.react-calendar__tile--active {
  background: #40918f !important;
}

.calendar {
  border: 2px solid #f2b250;
  padding: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 500 !important;
  min-width: 38vw !important;
}
.calendar * {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 12px;
}

.tile:hover {
}
