.image-gallery .awssld__wrapper {
  
}

.awssld {
  --loader-bar-color: #f2b250;
  --organic-arrow-color: #40918f;
  --organic-arrow-thickness: 15px;
  --slider-height-percentage: 70vh;
  --slider-transition-duration: 500ms;
  --control-bullet-color: #40918f;
  --control-bullet-active-color: #f2b250;
}

.awssld__bullets{
  margin-bottom: 50px;
}
