.tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1px;
}
.tab > *{
    margin-left : 6px;
}
