.content-container,
.content-container > * {
  margin: 16px;
  padding: 8px;
}

#main-container {
  position: relative;
}
.home-navigation-content {
  position: absolute;
  top: 430px;
  left: 25%;
  z-index: 1000;
}
